.modal {
  &__form {
    height: 80%;
  }
  &__checks {
    height: 80%;
    overflow-y: scroll;
  }
  &__clear {
    float: right;
  }
}

.modal-text-size {
  font-size: 90%;
}

.cat-header {
  background-color: #005ea2;
  color: #fff;
}

.category-link {
  color: #fff !important;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.normal-link {
  color: #005ea2 !important;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
a {
  // text-decoration: none;
}

.view-definition {
  font-size: 90%;
}

div.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.search-count {
  background: yellow;
  white-space: nowrap;
}

.search-highlight {
  background: yellow;
}

table.category-details thead th {
  background-color: #e8f5ff;
  color: black;
}

.text-no-break {
  white-space: nowrap;
}

.font-normal {
  font-weight: normal;
}

.gray-border {
  border: 1px solid #dee2e6;
}
//override bootstrap
.table > :not(:first-child) {
  border-top: 1px solid #dee2e6;
}
//SkipNav
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*----------Search section----------------*/
.flex-container {
  display: flex;
  flex-wrap: wrap;
}
.search-d1 {
  flex-grow: 4;
}
.search-d2 {
  flex-grow: 1;
}
.search-d3 {
  flex-grow: 5;
}
/*--------------PRINT---------------*/
/*this automaticaslly enable 'Backgroud graphics" on print*/
* {
  -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}

.pdf-header {
  display: none;
}
@media print {
  .cdc-header {
    display: none;
  }
  .cdc-footer {
    display: none;
  }
  .pdf-header {
    display: block;
  }
  .search {
    display: none;
  }
  .expand-collase {
    display: none;
  }
  .file-format {
    display: none;
  }
  .edit-remove-link {
    display: none;
  }
  .view-definition {
    display: none;
  }
  .row-indicator {
    display: none;
  }
  thead {
    display: table-row-group;
  }
}

.site-nav {
  color: #005ea2 !important;
}
// .site-nav a,
// a:visited {
//   color: #fff;
// }
.breadcrumb a {
  color: #005ea2;
  text-decoration: none;
  font-size: large !important;
}
.pdf-title {
  background-color: #005ea2;
  color: #fff;
  font-size: 28px !important;
  text-align: center;
}
.pdf-title-logo {
  width: fit-content;
  height: auto;
  max-width: 150px !important;
}
.btn-custom {
  background-color: #005ea2 !important;
}
.bg-primary-custom {
  background-color: #005ea2 !important;
  color: #fff;
}
